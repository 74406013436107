import request from '@/utils/request.js'
// 新闻分类
export function fetchNewsList(data) {
    return request({
      url: '/api/getCategoryList',
      method: 'get',
      data
    })
}

export function archivesList(data) {
  let id = data.id
  delete data.id
  return request({
    url: '/api/category/'+''+id,
    method: 'get',
    data
  })
}

export function archivesDetail(data) {
  let id = data.id
  delete data.id
  return request({
    url: '/api/info/'+''+id,
    method: 'get',
    // data
  })
}